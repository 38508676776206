<template>
  <div>
    <div class="d-flex align-items-center py-3">
      <h1 class="h3 mr-3 mb-0">Questions - New</h1>
    </div>

    <h4>Question context:</h4>

    <b-row class="justify-content-center">
      <b-col md="4">
        <b-form-group label="Locale">
          <multiselect
            v-model="locale"
            :options="locales"
            track-by="id"
            label="name"
            placeholder="Select one"
            :searchable="true"
            :allow-empty="true"
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Form">
          <multiselect
            v-model="form"
            :options="forms"
            @input="changeForm"
            track-by="id"
            label="name"
            :custom-label="
              ({ name, apiName }) => {
                return `${name} - (${apiName})`;
              }
            "
            placeholder="Select one"
            :searchable="true"
            :allow-empty="true"
          >
            <template slot="option" slot-scope="props">
              <div class="option__name">{{ props.option.name }}</div>
              <div class="option__desc">
                <b-tag class="option__small" tag-variant :no-remove="true">
                  {{ props.option.apiName }}
                </b-tag>
              </div>
            </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Flows">
          <multiselect
            v-model="flow"
            :options="flows"
            track-by="id"
            label="name"
            :custom-label="
              ({ name, step }) => {
                return `${name} - (${step})`;
              }
            "
            placeholder="Select one"
            :searchable="true"
            :allow-empty="true"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <h4>Question basic inputs:</h4>

    <b-row class="justify-content-left">
      <b-col md="4">
        <b-form-group label="Name">
          <b-input-group>
            <b-form-input v-model="name" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Title">
          <b-input-group>
            <b-form-input v-model="title" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Type">
          <multiselect
            v-model="type"
            :options="types"
            @input="changeType"
            track-by="id"
            label="name"
            placeholder="Select one"
            :searchable="true"
            :allow-empty="true"
          />
        </b-form-group>
      </b-col>
      <b-col md="1">
        <b-form-group label="Sort">
          <b-input-group>
            <b-form-input v-model="sort" type="number" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="1" v-if="hasMultiple">
        <b-form-group label="Multiple">
          <b-input-group>
            <b-form-checkbox
              v-model="sort"
              size="lg"
              :value="true"
              :unchecked-value="false"
              type="number"
            ></b-form-checkbox>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Explanation">
          <b-input-group>
            <b-form-textarea v-model="explanation" rows="3" max-rows="8" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" v-if="hasOptions">
        <b-form-group label="Options">
          <b-input-group
            class="mb-2"
            v-for="(option, index) in options"
            :key="index"
          >
            <b-form-input v-model="option.value" />
            <b-input-group-append>
              <b-button @click="removeField(index, options)">
                <i class="material-icons">remove</i>
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-button
            size="md"
            variant="primary"
            class="mb-2"
            @click="addField('', options)"
          >
            <i class="material-icons">add</i>
          </b-button>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Default answer">
          <b-input-group>
            <b-form-input v-model="defaultAnswer" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <h4>Question advanced inputs:</h4>

    <b-row class="justify-content-left">
      <b-col md="6">
        <b-form-group label="Depends On">
          <b-form-textarea v-model="dependsOn" rows="3" max-rows="8" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Metadata">
          <b-form-textarea v-model="metadata" rows="3" max-rows="8" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Store as job property">
          <b-form-input v-model="storeAsJobProperty" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Required">
          <b-form-checkbox v-model="required" />
        </b-form-group>
        <b-form-group label="Job Property to be populated">
          <multiselect
            v-model="populatedJobField"
            :options="populatedJobFields"
            track-by="id"
            label="name"
            placeholder="Select one"
            :searchable="true"
            :allow-empty="true"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="justify-content-center">
      <b-spinner v-if="buttons.create.loading" class="gid-spinner--button mr-2">
        {{ buttons.create.label }}
      </b-spinner>
      <b-button
        :disabled="!canCreate || buttons.create.loading"
        variant="primary"
        class="mr-2"
        @click="create"
      >
        {{ buttons.create.label }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { OrderFormQuestionTypeEnum } from '@gid/models/dist/entities/order-form-questions/order-form-question-type';
import { OrderFormQuestionPopulatedJobFieldEnum } from '@gid/models/dist/entities/order-form-questions/order-form-question-populated-job-field-enum';

export default {
  name: 'QuestionsNew',
  data() {
    return {
      buttons: {
        create: {
          loading: false,
          label: 'Create',
        },
      },
      api: {
        forms: {
          method: 'GET',
          url: '/data-api/form',
        },
        flows: {
          method: 'GET',
          url: '/data-api/form-flow',
        },
        create: {
          method: 'POST',
          url: '/data-api/order-form-question',
        },
      },
      forms: [],
      locales: [
        { id: 'de', name: 'Deutsch' },
        { id: 'en', name: 'English' },
        { id: 'fr', name: 'Français' },
      ],
      flows: [],
      types: Object.values(OrderFormQuestionTypeEnum).map((x) => {
        return { id: x, name: x };
      }),
      form: null,
      locale: null,
      flow: null,
      name: null,
      title: null,
      multiple: false,
      sort: null,
      type: null,
      explanation: null,
      required: false,
      options: [{ value: '' }],
      defaultAnswer: null,
      internalDependsOn: {},
      internalMetadata: {},
      storeAsJobProperty: null,
      populatedJobFields: Object.values(
        OrderFormQuestionPopulatedJobFieldEnum,
      ).map((x) => {
        return { id: x, name: x };
      }),
      populatedJobField: null,
    };
  },
  computed: {
    canCreate() {
      return (
        this.form?.id &&
        this.flow?.id &&
        this.locale?.id &&
        this.name &&
        this.title &&
        !Number.isNaN(parseInt(this.sort)) &&
        this.type?.id
      );
    },
    hasOptions() {
      return this.type && ['select', 'radio'].includes(this.type.id);
    },
    hasJobFieldOptions() {
      return this.populatedJobFields;
    },
    hasMultiple() {
      return this.type && ['select'].includes(this.type.id);
    },
    dependsOn: {
      get() {
        return JSON.stringify(this.internalDependsOn, null, 2);
      },
      set(val) {
        this.internalDependsOn = JSON.parse(val);
      },
    },
    metadata: {
      get() {
        return JSON.stringify(this.internalMetadata, null, 2);
      },
      set(val) {
        this.internalMetadata = JSON.parse(val);
      },
    },
  },
  methods: {
    isObjectEmpty(obj) {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    addField(value, fieldRef) {
      fieldRef.push({ value: value });
    },
    removeField(index, fieldRef) {
      fieldRef.splice(index, 1);
    },
    loadForms() {
      axios
        .request({
          method: this.api.forms.method,
          url: this.api.forms.url,
        })
        .then(({ data }) => {
          this.forms = data.map((x) => {
            return { id: x.id, name: x.name, apiName: x.apiName };
          });
        });
    },
    loadReferenceFlows() {
      let url = this.api.flows.url;
      if (this.form?.id) {
        url += `?filter[0]=form.id||$in||${this.form.id}`;
      }
      axios
        .request({
          method: this.api.flows.method,
          url: url,
        })
        .then(({ data }) => {
          this.flows = data.map((x) => {
            return {
              id: x.flow.id,
              step: x.step,
              name: x.flow.translations.find((t) => t.locale === this.locale.id)
                .headline,
            };
          });
        });
    },
    changeForm() {
      if (this.form) {
        this.flow = null;
        this.loadReferenceFlows();
      }
    },
    changeType() {
      if (!this.hasOptions) {
        this.options = [];
      }
    },
    clearFormFields() {
      this.form = null;
      this.locale = null;
      this.flow = null;
      this.name = null;
      this.title = null;
      this.multiple = false;
      this.sort = null;
      this.type = null;
      this.explanation = null;
      this.defaultAnswer = null;
      this.internalDependsOn = {};
      this.internalMetadata = {};
      this.required = false;
    },
    create() {
      this.buttons.create.loading = true;

      axios
        .request({
          method: this.api.create.method,
          url: this.api.create.url,
          data: {
            name: this.name,
            questionType: this.type.id,
            sort: this.sort,
            multiple:
              OrderFormQuestionTypeEnum.FILE == this.type?.id
                ? true
                : this.multiple,
            metadata: !this.isObjectEmpty(this.metadata)
              ? JSON.parse(this.metadata)
              : null,
            dependsOn: !this.isObjectEmpty(this.dependsOn)
              ? JSON.parse(this.dependsOn)
              : null,
            flow: { id: this.flow.id },
            storeAsJobProperty: this.storeAsJobProperty,
            required: this.required,
            populatedJobField: this.populatedJobField?.id || null,
            translations: [
              {
                locale: this.locale.id,
                title: this.title,
                explanation: this.explanation,
                options: this.options.length
                  ? this.options.map((opt) => opt.value)
                  : null,
                defaultAnswer: this.defaultAnswer ? this.defaultAnswer : null,
              },
            ],
          },
        })
        .then(({ data }) => {
          if (data) {
            this.renderSuccess(data, 'Question creation succeeded');
            this.clearFormFields();
          } else {
            this.renderError(data.error, 'Question creation failed');
          }
        })
        .catch((err) => {
          this.renderError(err, 'Question creation failed');
        })
        .finally(() => {
          this.buttons.create.loading = false;
        });
    },
    errorMessage(error) {
      return 'Something went wrong. Have a look in error details below for more information.';
    },
    showErrorDetails(error) {
      const errData =
        error?.request && error?.response
          ? {
              request: error.request,
              response: error.response,
            }
          : error;
      const contents = [
        this.$createElement('pre', JSON.stringify(errData, null, '  ')),
      ];
      this.$bvModal.msgBoxOk(contents, {
        title: 'Error details',
        size: 'lg',
        centered: true,
      });
    },
    renderError(error, title) {
      const h = this.$createElement;
      const contents = [
        h('p', this.errorMessage(error)),
        h(
          'b-button',
          {
            props: {
              variant: 'outline-danger',
              size: 'sm',
            },
            on: {
              click: () => {
                this.showErrorDetails(error);
              },
            },
          },
          'Error details',
        ),
      ];
      this.$bvToast.toast(contents, {
        title,
        variant: 'danger',
      });
    },
    renderSuccess(data, title) {
      const h = this.$createElement;
      const contents = [h('p', 'Question has been created.')];
      this.$bvToast.toast(contents, {
        title,
        variant: 'success',
      });
    },
  },
  created() {
    this.loadForms();
  },
};
</script>

<style lang="scss">
ul.multiselect__content {
  li.multiselect__element span {
    div.option__desc {
      margin-top: 5px;
    }
  }
}
</style>
